import React from "react";
import HelpPage from "../../components/helpPage";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";

export const query = graphql`
  query {
    zdjecie1: file(relativePath: { eq: "Prosty-system-CRM-dodawanie-kontrahenta.png" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(relativePath: { eq: "Prosty-system-CRM-tworzenie-atrybutow-firm-kontrahenta.png" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie3: file(
      relativePath: { eq: "Prosty-system-CRM-tworzenie-atrybutow-kontrahenta.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie4: file(
      relativePath: { eq: "Prosty-system-CRM-tworzenie-atrybutow-kontrahentow.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie5: file(
      relativePath: { eq: "Prosty-system-CRM-atrybuty-w-karcie-kontrahenta-1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Kontrahenci = ({ data }) => {
  return (
    <HelpPage
      name="Kontrahenci"
      metaTitle="Pomoc - kontrahenci w prostym systemie CRM - QuickCRM"
      metaDescription="Kontrahenci w prostym systemie CRM - pomoc • Wszyscy kontrahenci w jednym miejscu • Wypróbuj QuickCRM i sprzedawaj więcej, pierwsze 30 dni bezpłatnie!"
    >
      <div>
        <p>
          Nowi kontrahenci mogą zotać dodani do systemu z poziomu listy kontrahentów lub poprzez <Link to="/import-danych/">import danych</Link> z pliku. Jeśli korzystasz ze zdefiniowanych domyślnie skrótów na pulpicie – również masz taką możliwość. QuickCRM pomoże Ci stworzyć spójną i uporządkowaną bazę więdzy o kontrahentach. Oprócz podstawowych informacji o Twoich kontrahentach oraz historii kontaktów z nimi, kartoteki kontrahentów mogą zostać wzbogacone o dowolnie dobrane przez Ciebie atrybuty. Atrybuty ułatwiają opis firm, z którymi współpracujesz.
        </p>
      </div>
      <Tabs defaultTab="zakladka1">
        <TabList>
          <Tab tabFor="zakladka1">[1] Dodawanie kontrahenta</Tab>
          <Tab tabFor="zakladka2">[2] Tworzenie atrybutów</Tab>
        </TabList>
        <TabPanel tabId="zakladka1">
          <p>
            <strong>Dodawanie kontrahenta z widoku listy kontrahentów</strong>
          </p>
          <br />
          <p>
            Aby przejść do listy wszystkich kontrahentów, wybierz w menu “Kontrahenci”.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Prosty-system-CRM-dodawanie-kontrahenta"
            fluid={data.zdjecie1.childImageSharp.fluid}
          />
          <p>
           1] Jeśli chcesz dodać kontrahenta, wybierz przycisk “dodaj”, a następnie uzupełnij wszystkie ważne dla Ciebie informacje i zapisz. Będą one dostępne w kartotece kontrahenta zawsze, gdy będziesz ich potrzebował.
          </p>
          <p>
            Po zapisaniu kontrahent będzie dostępny na liście kontrahentów. Aby edytować informacje na temat konkretnego kontrahenta, przejdź do jego karty, wybierając nazwę z listy.
          </p>
          
        </TabPanel>
        <TabPanel tabId="zakladka2">
          <p>
            <strong>Tworzenie własnych atrybutów do firm kontrahentów</strong>
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Prosty-system-CRM-tworzenie-atrybutów-firm-kontrahenta"
            fluid={data.zdjecie2.childImageSharp.fluid}
          />
          <p>[1] Wybierz z menu “Ustawienia”, a następnie “Parametry kontrahentów”.</p>
          <p>2] Jeśli chcesz dodać własne atrybuty, wybierz “Atrybuty kontrahentów — firm”.</p>
          <p>[3] Wyświetlone zadania pracownika.</p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Prosty-system-CRM-tworzenie-atrybutów-kontrahenta"
            fluid={data.zdjecie3.childImageSharp.fluid}
          />
          <p>[3] Znajdujesz się na liście kontrahentów. Kliknij “dodaj nowy”, aby przejść do ustawień atrybutu.</p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Prosty-system-CRM-tworzenie-atrybutów-kontrahentów"
            fluid={data.zdjecie4.childImageSharp.fluid}
          />
          <p>[4] Uzupełnij wszystkie pola, które są dla Ciebie istotne. Dodaj nazwę, wybierz typ pola oraz określ jego wielkość, ustaw kolejność itp.</p>
          <p>[5] Pamiętaj, aby znaczyć opcję “jest aktywne” i “widoczny”.</p>
          <p>[6] Zapisz zmiany.</p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Prosty-system-CRM-atrybuty-w-karcie-kontrahenta-1"
            fluid={data.zdjecie5.childImageSharp.fluid}
          />
          <p>[7] Atrybuty firm kontrahentów znajdziesz w zakładce “Atrybuty” w widoku karty kontrahenta.</p>

        </TabPanel>
        
      </Tabs>
    </HelpPage>
  );
};

export default Kontrahenci;
